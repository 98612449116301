import React, { Suspense, lazy } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { AuthorizedPage } from '../middlewares/AuthorizedPage';
import { ModalProvider } from '../contexts/modalContext';
import { Dashboard, AdminDashboard, AuthDashboard } from '../pages/dashboard';
import { Register, Login, ResetAdminPassword, 
  VerifyOtp, VerifyAdminPhoneNumber, 
  ResetPassword, ResetOthersPassword, 
  LocationMaps, ApplicationCheck, CreateAdminApplication, 
  AddPhoneNumber
} from '../pages/auth';
import { NotFound } from '../pages/error';
import { CreateRequestSuccess, AcceptRequestSuccess, ConnectAccountSuccess, SubmitApplicationSuccess } from '../pages/payment';
import { ProfileDetails } from '../pages/profile';
import { ListOperations, ListAssignedOperations, ListApprovedOperations } from '../pages/dashboard/operations-admin';
import { ListBillings, ListAssignedBillingUsers, ListApprovedBillingUsers } from '../pages/dashboard/billings-admin';
import { ListAgentApplications, ListAssignedAgents } from '../pages/dashboard/agents';
import { pageURL } from '../components/navigation/pagesURL';
import { Support } from '../pages/support';
import { Landing } from '../pages/landing';
import { PrivacyPolicy, TermsAndConditions, AgentAgreement } from '../pages/legal';
import { About } from '../pages/about';
import { Contact } from '../pages/contact';
import { DesktopNavigation } from '../components/header';
import { Footer } from '../components/footer';
import { AppDownload } from '../pages/app-download';
import { FAQuestions } from '../pages/faq';
import { 
  ListAgentDisputes, 
  ListAssignedDisputes,
  DisputeDetails, 
  AssignedDisputeDetails, 
  ListResolvedDisputes
 } from '../pages/dashboard/disputes';
import { GoogleVerifyCode } from '../pages/auth/social';
import { 
  ListBrokersNoProfile, 
  ListBrokersCompletedProfile, 
  ListBrokerAgents, 
  BrokerageAgentDetails,
  BrokerageAgentShowingsDetails 
} from '../pages/dashboard/brokers';
import { 
  ListBrokerageCreatedShowings,
  ListBrokerageAcceptedShowings, 
  ListBrokerageCompletedShowings, 
  ListBrokerageConfirmedShowings,
  ListBrokerageDisputedShowings
  } from '../pages/dashboard/brokers/brokerage-showings';
import { ListSingleBrokerageAgentShowings } from '../pages/dashboard/brokers/single-brokerage-agent-showings';

const PageRoutes = () => {
  return (
    <Router>
        {/* <DesktopNavigation /> */}
        <Suspense fallback={<div>Loading...</div>}>
        <Routes>
            <Route path={pageURL.landing} element={<Landing/>} />
            <Route path={pageURL.signIn} element={<Login/>} />
            <Route path={pageURL.googleVerifyCode} element={<GoogleVerifyCode/>} />
            {/* <Route path={pageURL.registration} element={<Register/>} /> */}
            <Route path={pageURL.maps} element={<LocationMaps/>} />
            <Route path={pageURL.resetAdminPassword} element={<ResetAdminPassword/>} />
            <Route path={pageURL.resetOthersPassword} element={<ResetOthersPassword/>} />
            <Route path={pageURL.addPhoneNumber} element={<AddPhoneNumber/>} />
            <Route path={pageURL.verifyPhoneNumber} element={<VerifyAdminPhoneNumber/>} />
            <Route path={pageURL.resetPassword} element={<ResetPassword/>} />
            <Route path={pageURL.verifyOtp} element={<VerifyOtp/>} />
            <Route path={pageURL.about} element={<About/>} />
            <Route path={pageURL.contact} element={<Contact/>} />
            <Route path={pageURL.support} element={<Support/>} />
            <Route path={pageURL.privacyPolicy} element={<PrivacyPolicy/>} />
            <Route path={pageURL.termsAndConditions} element={<TermsAndConditions/>} />
            <Route path={pageURL.agentAgreement} element={<AgentAgreement/>} />
            <Route path={pageURL.appDownload} element={<AppDownload/>} />
            <Route path={pageURL.faq} element={<FAQuestions/>} />
            {/* start payment success */}
            <Route path={pageURL.createRequestPaymentSuccess} element={<CreateRequestSuccess />} />
            <Route path={pageURL.createApplicationPaymentSuccess} element={<SubmitApplicationSuccess />} />
            <Route path={pageURL.cancelRequestPaymentSuccess} element={<AcceptRequestSuccess />} />
            <Route path={pageURL.connectAccountPaymentSuccess} element={<ConnectAccountSuccess />} />
            {/* end payment success */}
            <Route path={pageURL.applicationCheck} element={<ApplicationCheck />}/>
            <Route path={pageURL.createAdminApplication} element={<CreateAdminApplication />}/>
            <Route element={<ModalProvider><Dashboard /></ModalProvider>}>
              <Route index path={pageURL.authDashboard} element={<AuthDashboard />} />
              <Route path={pageURL.listAgentApplications} element={<ListAgentApplications />} />
              <Route path={pageURL.listAssignedAgents} element={<ListAssignedAgents />} />

              {/* operation users */}
              <Route path={pageURL.listOperations} element={<ListOperations />} />
              <Route path={pageURL.listAssignedOperations} element={<ListAssignedOperations />} />
              <Route path={pageURL.listApprovedOperations} element={<ListApprovedOperations />} />

              {/* billing users  */}
              <Route path={pageURL.listBillings} element={<ListBillings />} />
              <Route path={pageURL.listAssignedBillingUsers} element={<ListAssignedBillingUsers />} />
              <Route path={pageURL.listApprovedBillingUsers} element={<ListApprovedBillingUsers />} />

              {/* brokers */}
              <Route path={pageURL.listBrokersNoProfile} element={<ListBrokersNoProfile />} />
              <Route path={pageURL.listBrokersCompletedProfile} element={<ListBrokersCompletedProfile />} />
              <Route path={pageURL.listBrokerAgents} element={<ListBrokerAgents />} />
              <Route path={pageURL.listBrokerageAgentCreatedShowings} element={<ListBrokerageCreatedShowings />} />
              <Route path={pageURL.listBrokerageAgentAcceptedShowings} element={<ListBrokerageAcceptedShowings />} />
              <Route path={pageURL.listBrokerageAgentConfirmedShowings} element={<ListBrokerageConfirmedShowings />} />
              <Route path={pageURL.listBrokerageAgentCompletedShowings} element={<ListBrokerageCompletedShowings />} />
              <Route path={pageURL.listBrokerageAgentDisputedShowings} element={<ListBrokerageDisputedShowings />} />
              <Route path={pageURL.listSingleBrokerageAgentShowings} element={<ListSingleBrokerageAgentShowings />} />

              {/* disputes */}
              <Route path={pageURL.listDisputes} element={<ListAgentDisputes />} />
              <Route path={pageURL.listAssignedDisputes} element={<ListAssignedDisputes />} />
              <Route path={pageURL.listResolvedDisputes} element={<ListResolvedDisputes />} />

              <Route path='agent-profile/:slug' element={<ProfileDetails />} />
              <Route path='dispute-details/:slug' element={<DisputeDetails />} />
              <Route path='assigned-dispute-details/:slug' element={<AssignedDisputeDetails />} />
              <Route path={`${pageURL.brokerageAgentDetails}/:slug`} element={<BrokerageAgentDetails />} />
              <Route path={`${pageURL.brokerageAgentShowingsDetails}/:slug`} element={<BrokerageAgentShowingsDetails />} />
            </Route>
           <Route path='*' element={<NotFound />} />
        </Routes>
        </Suspense>
        {/* <Footer /> */}
    </Router>
  )
}

export default PageRoutes