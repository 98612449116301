



export const pageURL = {
    landing: '/',
    registration: '/register',
    signIn: '/sign-in',
    maps: '/maps',
    googleVerifyCode: '/auth/google/callback',
    resetAdminPassword: '/xchange/verify-admin-token',
    resetOthersPassword: '/xchange/verify-others-token',
    addPhoneNumber: '/xchange/add-phone-number',
    verifyPhoneNumber: '/xchange/verify-phone-number',
    resetPassword: '/xchange/reset-password',
    verifyOtp: '/xchange/verify-otp',
    createRequestPaymentSuccess: '/exchange/success',
    createApplicationPaymentSuccess: '/exchange/success/application',
    cancelRequestPaymentSuccess: '/exchange/success/cancel',
    connectAccountPaymentSuccess: '/exchange/success/connection',
    adminDashboard: '/exchange/admin-dashboard',
    authDashboard: '/exchange/auth-dashboard',
    agentProfile: '/agent-profile',

    listOperations: '/xchange/list-operations',
    listAssignedOperations: '/xchange/list-assigned-operations',
    listApprovedOperations: '/xchange/list-approved-operations',

    listBillings: '/xchange/list-billings',
    listAssignedBillingUsers: '/xchange/list-assigned-billings',
    listApprovedBillingUsers: '/xchange/list-approved-billings',

    //brokers
    listBrokersNoProfile: '/list-brokers-no-profile',
    listBrokersCompletedProfile: '/list-brokers-completed-profile',
    listBrokerAgents: '/list-broker-agents',

    listBrokerageAgentCreatedShowings: '/list-brokerage-agent-created-showings',
    listBrokerageAgentAcceptedShowings: '/list-brokerage-agent-accepted-showings',
    listBrokerageAgentConfirmedShowings: '/list-brokerage-agent-confirmed-showings',
    listBrokerageAgentCompletedShowings: '/list-brokerage-agent-completed-showings',
    listBrokerageAgentDisputedShowings: '/list-brokerage-agent-disputed-showings',

    listSingleBrokerageAgentShowings: '/list-single-brokerage-agent-showings',

    applicationCheck: '/xchange/application-check',
    createAdminApplication: '/xchange/create-application',
    listAgentApplications: '/xchange/list-agent-applications',
    listAssignedAgents: '/xchange/list-assigned-agents',
    listDisputes: '/xchange/list-disputes',
    listAssignedDisputes: '/xchange/list-assigned-disputes',
    listResolvedDisputes: '/xchange/list-resolved-disputes',
    about: '/about-us',
    contact: '/contact-us',
    faq: '/frequently-asked-questions',
    appDownload: '/app-download',
    support: '/support',

    // item details
    brokerageAgentDetails: '/brokerage-agent-details',
    brokerageAgentShowingsDetails: '/brokerage-agent-showings-details',

    

    // legal
    privacyPolicy: '/legal/privacy-policy',
    termsAndConditions: '/legal/terms-and-conditions',
    agentAgreement: '/legal/agent-agreement',
}