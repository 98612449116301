
import React, { useEffect, useState} from 'react';
import { Formik, Form } from "formik";
import { useNavigate, useLocation } from 'react-router-dom';
import { toast } from 'sonner';
import { format } from 'date-fns';
import { Search } from 'lucide-react';
import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';
import { AiOutlineClose } from 'react-icons/ai'
import { useGetRequest } from '../../hooks/get/useGetRequest';
import { useAuthPostRequest } from '../../hooks/post/useAuthPostRequest';

import { getBrokersNoProfilePath, getBrokersCompletedProfilePath, listCouponCodesPath } from '../../config/api-urls';
import { PlaceholderCard } from '../../components/skeleton';
import { NoData } from '../../components/placeholders';
import { SettingsBtn } from '../../components/button';
import CustomModal from '../../components/modal/CustomModal';
import { SimpleSpinner } from '../../components/loaders';
import logo from '../../assets/png/showingxchange-logo.png';
import { ItemSearch } from '../../components/search';
import { CopyToClipboard } from '../../components/copy-items';
import { CreateCouponCodeForm } from '../../components/forms/user-forms';

const BillingDashboard = () => {
    const getRequest = useGetRequest();
    const authPostRequest = useAuthPostRequest();
    const queryClient = useQueryClient();
    const location = useLocation();
    // const profileId = location.state;


    const [searchQuery, setSearchQuery] = useState<string>('');
    const [selectedProfileId, setSelectedProfileId] = useState<string>('');
    const [localProfileId, setLocalProfileId] = useState(location.state);

    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isCouponCodeModalOpen, setIsCouponCodeModalOpen] = useState(false);
    const handleOpenModal = () => setIsModalOpen(true);
    const handleCloseModal = () => setIsModalOpen(false);

    const handleOpenCouponCodeModal = () => setIsCouponCodeModalOpen(true);
    const handleCloseCouponCodeModal = () => setIsCouponCodeModalOpen(false);



    const handleSearchQuery = (e: React.ChangeEvent<HTMLInputElement>) => {
        setSearchQuery(e.target.value);
    };

    const handleSelectedProfile = (profileId: string)=>{
        setSelectedProfileId(profileId);
        handleOpenModal();
    }

    const { isPending, error, data } = useQuery({
        queryKey: ['coupon-codes'],
        queryFn: ()=> getRequest(listCouponCodesPath),
        retry: false
    });

    const listCouponCodes  = data?.data?.data || []

    

    const filteredQuery = Array.isArray(listCouponCodes)
    ? listCouponCodes.filter((code: any) => {
        const result = `${code?.description} ${code?.code} ${code?.coupon_percent}`;
        return result.toLowerCase().includes(searchQuery.toLowerCase());
    })
    : [];

    function couponValidityPeriod(fromDate: string, toDate: string){
        const createdDate = format(new Date(fromDate),"MMM d, yyyy",);
        const expiryDate = format(new Date(toDate),"MMM d, yyyy",);

        return `${createdDate} - ${expiryDate}`
    }


  return (
    <div className='font-primary'>
        {
            isPending ? (
                <div className="grid grid-cols-1 gap-4 sm:grid-cols-2 lg:grid-cols-1">
                    {Array.from({ length: 8 }).map((_, i) => (
                        <PlaceholderCard key={i} />
                    ))}
                </div>
            ):(
                <>
                    {listCouponCodes && listCouponCodes.length > 0 ? (
                    <div className='bg-white rounded-md shadow-lg mx-3 p-5 mt-5'>
                    <div className='flex justify-end'>
                        <SettingsBtn
                            isBorder={false}
                            bgColor="bg-customPrimary"
                            textColor="text-white"
                            width="md:w-2/12"
                            onClick={handleOpenCouponCodeModal}
                        >
                        Create Coupon
                        </SettingsBtn>
                    </div>
                    <div className='flex items-center justify-between border-b pb-3'>
                    <h3 className=' text-xsm'>Number of coupon codes created with created <strong>({listCouponCodes && listCouponCodes.length})</strong></h3>
                    </div>
                 
                    <ItemSearch
                    searchQuery={searchQuery}
                    handleSearchQuery={handleSearchQuery}
                    />
                    <div className='h-auto my-5 overflow-y-auto'>
                    {   
                        <div className='overflow-y-auto'>
                            <table className="table table-sm">
                            <thead>
                                <tr>
                                  <th></th>
                                <th>S/N</th>
                                <th>Coupon code</th>
                                <th>Applicable area</th>
                                <th>Percentage (%)</th>
                                <th>Max number of usage</th>
                                <th>Validity period</th>
                                <th>Action</th>
                                </tr>
                            </thead>
                            <>
                              {
                                filteredQuery.length > 0 
                                ? (
                                <>
                                  {
                                    filteredQuery && filteredQuery?.map((coupon: any, i: number)=>(
                                    <>
                                      <tbody key={i}>
                                      {/* row 1 */}
                                      <tr>
                                      <th>
                                          <label>
                                          <input type="checkbox" 
                                          className="checkbox checked:border-[#3EB489 [--chkbg:#808080] [--chkfg:#fff]" />
                                          </label>
                                      </th>
                                      <td>
                                          <div className=" w-9 h-9 border border-slate-400 rounded-full flex justify-center items-center">
                                                  <strong>{i +1 }</strong>
                                          </div>
                                      </td>
                                      <td>
                                          <span className="badge badge-ghost badge-sm">{coupon?.code}</span>
                                      </td>
                                      <td>
                                          <span className="badge badge-ghost badge-sm">{coupon?.coupon_applicable}</span>
                                      </td>
                                      <td>
                                          <span className="badge badge-ghost badge-sm">{coupon?.coupon_percent}</span>
                                      </td>
                                      <td>
                                          <span className="badge badge-ghost badge-sm">{coupon?.max_use_number}</span>
                                      </td>
                                      <td>
                                          <span className="badge badge-ghost badge-sm">{couponValidityPeriod(coupon?.valid_from, coupon?.valid_to)}</span>
                                      </td>
                                      <th>
                                          <button className="btn btn-ghost btn-xs" onClick={()=> handleSelectedProfile(coupon?.code)}>Copy Coupon code</button>
                                      </th>
                                      {/* <th>
                                          <button className="btn btn-ghost btn-xs" onClick={()=>{}}>Details</button>
                                      </th> */}
                                      </tr>
                                  </tbody>
                                    </>
                                    ))
                                  }
                                </>)
                                : 
                                (<div  className='h-full flex justify-center items-center mt-10'>
                                  <h3>No result found for <strong>{searchQuery}</strong> </h3>
                                </div>)
                              }
                            </>
                            </table>
                        </div>
                        
                    }
                    </div>
                </div>
                    ) : (
                        <div className='h-screen flex flex-col gap-5 justify-center items-center'>
                            <NoData icon={<Search size={30} />}>
                                <h3 className='text-normal'>No coupons found</h3>
                            </NoData>
                            <SettingsBtn
                                isBorder={false}
                                bgColor="bg-customPrimary"
                                textColor="text-white"
                                width="md:w-2/12"
                                onClick={handleOpenCouponCodeModal}
                            >
                            Create Coupon
                            </SettingsBtn>
                        </div>
                    )}
                </>
            )
        }
        <CustomModal
            width="w-[300px] md:w-3/12"
            icon={<img src={logo} alt='showingxchange logo' width={30} height={30} className='rounded-full border border-customPrimary p-1' />}
            iconRemove={<AiOutlineClose onClick={handleCloseModal} className="w-6 h-6 text-[#667085]" />}
            title="Coupon code"
            description="copy and share this coupon code"
            bgColor="bg-white"
            textColor="text-black"
            closeModal={handleCloseModal}
            isModalOpen={isModalOpen}
        >
          <CopyToClipboard
            link={selectedProfileId}
          />
        </CustomModal>
        <CustomModal
            width="w-[300px] md:w-6/12"
            icon={<img src={logo} alt='showingxchange logo' width={30} height={30} className='rounded-full border border-customPrimary p-1' />}
            iconRemove={<AiOutlineClose onClick={handleCloseCouponCodeModal} className="w-6 h-6 text-[#667085]" />}
            title="Create Coupon"
            description="complete the form to create a new coupon code"
            bgColor="bg-white"
            textColor="text-black"
            closeModal={handleCloseCouponCodeModal}
            isModalOpen={isCouponCodeModalOpen}
        >
         <CreateCouponCodeForm 
            closeModal={handleCloseCouponCodeModal}
         />
        </CustomModal>
    </div>
  )
}

export default BillingDashboard