export const listOperationApplicationsPath = '/list-operation-applications';


//brokrage api paths
export const adminSignsBrokerPath = 'admin-signs-broker';
export const getBrokersNoProfilePath = 'broker/get-brokers-no-profile';
export const getBrokersCompletedProfilePath = 'broker/get-brokers-profile-completed';
export const createBrokerProfilePath = 'broker/create-broker-profile';
export const getBrokersProfileCompletedPath = 'broker/get-brokers-profile-completed';

export const addBrokerageAgentsPath = 'broker/add-brokerage-agents';
export const getBrokerAgentsPath = 'broker/get-broker-agents';
export const getBrokerAgentApplicationDetailsPath = 'broker/get-agent-application-details';
export const getActivatedBrokerAgentsPath = 'broker/get-activated-broker-agents';
export const getDeactivatedBrokerAgentsPath = 'broker/get-deactivated-broker-agents';

export const activateBrokerageAgent = 'broker/activate-brokerage-agent';
export const deactivateBrokerageAgent = 'broker/deactivate-brokerage-agent';
export const reactivateBrokerageAgent = 'broker/reactivate-brokerage-agent';
export const brokerageAgentUpdateProfile = 'broker/brokerage-agent-update-profile';
export const deleteBrokerageAgent = 'broker/delete-brokerage-agent';
export const updateBrokerageFlags = 'broker/update-brokerage-flags';



export const brokerageAcceptedShowings = 'broker/brokerage-accepted-showings';
export const brokerageCompletedShowings = 'broker/brokerage-completed-showings';
export const brokerageConfirmedShowings = 'broker/brokerage-confirmed-showings';
export const brokerageDisputedShowings = 'broker/brokerage-disputed-showings';
export const brokerageCreatedShowings = 'broker/brokerage-created-showings';
export const brokerageDetailedShowing = 'broker/brokerage-detailed-showing';


// brokerage agents
export const brokerageAgentCreatedShowings = 'broker/brokerage-agent-created-showings';
export const brokerageAgentAcceptedShowings = 'broker/brokerage-agent-accepted-showings';
export const brokerageAgentCompletedShowings = 'broker/brokerage-agent-completed-showings';
export const brokerageAgentConfirmShowings = 'broker/brokerage-agent-confirm-showings';
export const brokerageAgentDisputes = 'broker/brokerage-agent-disputes';
//export const brokerageAgentCreatedShowings = 'brokerage-agent-payment';


export const createSpecificCoupon = 'create-coupon';
export const createGeneralCoupon = 'create-coupon';
export const listCouponCodesPath = 'list-coupons';

