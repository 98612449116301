
// import "@tremor/react/dist/esm/tremor.css";
import { Toaster } from 'sonner'
import { PageRoutes } from './routes';
import {
  QueryClient,
  QueryClientProvider,
} from '@tanstack/react-query'

const queryClient = new QueryClient();

function App() {
  return (
    <>
      <Toaster richColors position="top-right"/>
      <QueryClientProvider client={queryClient}>
        <PageRoutes />
      </QueryClientProvider>
    </>
  );
}

export default App;
