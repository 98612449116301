import React from 'react';
import { useNavigate } from 'react-router-dom';
import { LineChartHero, DoughnutChart } from '../../../components/graphs';

interface IStatCardProps {
  title: string;
  value: string;
  icon: React.ReactNode;
  redirectURL: string;
}

const StatsGraphs = ({ agents }: any) => {
  return (
    <div className='flex flex-col md:flex-row gap-x-5 px-3 mt-3'>
        <div className='md:w-2/3 shadow-lg px-3 rounded-md'>
            <LineChartHero />
        </div>
        <div className='md:w-1/2 shadow-lg px-3 rounded-md mt-10 md:mt-0'>
          <div className="grid grid-cols-2 md:grid-cols-3 gap-4 justify-items-stretch content-stretch">
            <div className='border p-5 md:p-7 flex flex-col items-center justify-center'>
              <h3 className='text-xxsm font-normal text-center'>Total Number Of Agents</h3>
              <h3 className='text-sm font-normal text-customSecondary'>{agents?.length || 0}</h3>
            </div>
            <div className='border p-5 md:p-7 flex flex-col items-center justify-center'>
              <h3 className='text-xxsm font-normal text-center'>Number Of Applications</h3>
              <h3 className='text-sm font-normal text-customSecondary'>22</h3>
            </div>
            <div className='border p-5 md:p-7 flex flex-col items-center justify-center'>
              <h3 className='text-xxsm font-normal text-center'>Total Number Of Agents</h3>
              <h3 className='text-sm font-normal text-customSecondary'>20</h3>
            </div>
            <div className='border p-5 md:p-7 flex flex-col items-center justify-center'>
              <h3 className='text-xxsm font-normal text-center'>Pending Applications</h3>
              <h3 className='text-sm font-normal text-yellow-500'>3</h3>
            </div>
            <div className='border p-5 md:p-7 flex flex-col items-center justify-center'>
              <h3 className='text-xxsm font-normal text-center'>Rejected Applications</h3>
              <h3 className='text-sm font-normal text-red-500'>1</h3>
            </div>
            <div className='border p-5 md:p-7 flex flex-col items-center justify-center'>
              <h3 className='text-xxsm font-normal text-center'>Approved Applications</h3>
              <h3 className='text-sm font-normal text-customSecondary'>23</h3>
            </div>
            {/* <StatCard redirectURL={pageURL.listBrokerageAgentAcceptedShowings} title="Accepted Showings" value={listAcceptedRequests?.length.toString() || '0'} icon={<FaPersonCircleCheck className="text-customPrimary" />}/>
            <StatCard redirectURL={pageURL.listBrokerageAgentCreatedShowings} title="Created Showings" value={listCreatedRequests?.length.toString() || '0'} icon={<FaHouseCircleCheck className="text-customPrimary" />} />
            <StatCard redirectURL={pageURL.listBrokerageAgentCompletedShowings} title="Completed Showings" value={listCompletedRequests?.length.toString() || '0'} icon={<CheckCheck className="text-customPrimary" />} />
            <StatCard redirectURL={pageURL.listBrokerageAgentDisputedShowings} title="Disputed Showings" value={listDisputedRequests?.length.toString() || '0'} icon={<Scale className="text-customPrimary" />} /> */}
          </div>
        </div>
    </div>
  )
}

function StatCard({ title, value, icon, redirectURL }: IStatCardProps ) {
  const navigateTo = useNavigate();
  const handleRedirect = (redirectURL: string)=>{
    navigateTo(redirectURL)
  };
  return (
    <div className="bg-white shadow-md p-4 rounded-lg cursor-pointer" onClick={()=>handleRedirect(redirectURL)}>
      <p className="text-gray-500 text-sm mb-1">{title}</p>
      <div className="flex items-center gap-3">
        <span className="text-sm">{icon}</span>
        <span className="text-sm font-normal text-navy-900">{value}</span>
      </div>
    </div>
  )
}

export default StatsGraphs