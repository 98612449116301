import React, { useState } from 'react';
import { Search, X } from 'lucide-react';
import { useNavigate } from 'react-router-dom';
import { useListAssignedDisputes } from '../../../hooks/get/useListAssignedDisputes';
import { useListResolvedDisputes } from '../../../hooks/get/useListResolvedDisputes';
import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';
import { NoData } from '../../../components/placeholders';
import { PlaceholderCard } from '../../../components/skeleton';
import { ItemSearch } from '../../../components/search';
import { pageURL } from '../../../components/navigation/pagesURL';



const ListResolvedDisputes = () => {
    const [searchQuery, setSearchQuery] = useState<string>('');
    const navigateTo = useNavigate();

    const handleSearchQuery = (e:any)=>{
        setSearchQuery(e.target.value);
    }

    

    const { isPending, error, data } = useQuery({
        queryKey: ['resolved_disputes'],
        queryFn: useListResolvedDisputes(),
        retry: false,
    });

    const disputes = data?.data?.data || [];

    const filterdQuery = disputes && disputes?.filter((agent:any)=>{
        const result = `${agent?.first_name} ${agent?.gender} ${agent?.last_name} ${agent?.agent_country}`;
        return result.toLocaleLowerCase().includes(searchQuery.toLocaleLowerCase()) 
    });

    const handleRedirect = (slug:string)=>{
        navigateTo('/assigned-dispute-details'+ '/' + slug);
      }

  return (
    <div className='font-primary'>
    {
      isPending ? (
        <div className="w-11/12 grid grid-cols-1 gap-4 sm:grid-cols-2 lg:grid-cols-1 m-auto mt-10">
            {Array.from({ length: 8}).map((_, i) => (
            <PlaceholderCard key={i} />
            ))}
        </div>
      ):(
        <>
          {
              disputes && disputes.length > 0 ? (
                  <div className='bg-white rounded-md shadow-lg mx-3 p-5 mt-5'>
                      <div className='flex items-center justify-between border-b pb-3'>
                      <h3 className=' text-xsm'>Number of resolved Disputes <strong>({disputes && disputes.length})</strong></h3>
                      </div>
                   
                      <ItemSearch
                      searchQuery={searchQuery}
                      handleSearchQuery={handleSearchQuery}
                      />
                      <div className='h-auto my-5 overflow-y-auto'>
                      {   
                          <div className='overflow-y-auto'>
                              <table className="table table-sm">
                              <thead>
                                  <tr>
                                    <th></th>
                                  <th>S/N</th>
                                  <th>Accepting Agent</th>
                                  <th>Creating Agent</th>
                                  <th>Status</th>
                                  {/* <th>Details</th>
                                  <th>Action</th> */}
                                  </tr>
                              </thead>
                              <>
                                {
                                  filterdQuery.length > 0 
                                  ? (
                                  <>
                                    {
                                      filterdQuery && filterdQuery.map((dispute: any, i: number)=>(
                                      <>
                                        <tbody key={i}>
                                        {/* row 1 */}
                                        <tr>
                                        <th>
                                            <label>
                                            <input type="checkbox" 
                                            className="checkbox checked:border-[#3EB489 [--chkbg:#808080] [--chkfg:#fff]" />
                                            </label>
                                        </th>
                                        <td>
                                            <div className=" w-9 h-9 border border-slate-400 rounded-full flex justify-center items-center">
                                                    <strong>{i +1 }</strong>
                                            </div>
                                        </td>
                                        <td>
                                            <span className="badge badge-ghost badge-sm">{dispute?.resolution_status}</span>
                                        </td>
                                        <td>
                                            <span className="">{dispute?.accepted_agent_other_details?.first_name}</span> <br />
                                            <span className="">{dispute?.accepted_agent_other_details?.last_name}</span> 
                                        </td>
                                        <td>
                                        <span className="">{dispute?.created_agent_other_details?.first_name}</span> <br />
                                        <span className="">{dispute?.created_agent_other_details?.last_name}</span> 
                                        </td>
                                        {/* <th>
                                            <button className="btn btn-ghost btn-xs" onClick={()=>handleRedirect(dispute?.slug)}>View Details</button>
                                        </th>
                                        <th>
                                            <button className="btn btn-ghost btn-xs" onClick={()=>{
                                            }}>Assign to me</button>
                                        </th> */}
                                        </tr>
                                    </tbody>
                                      </>
                                      ))
                                    }
                                  </>)
                                  : 
                                  (<div  className='h-full flex justify-center items-center mt-10'>
                                    <h3>No result found for <strong>{searchQuery}</strong> </h3>
                                  </div>)
                                }
                              </>
                              </table>
                          </div>
                          
                      }
                      </div>
                  </div>
              ):(
                <div className='h-screen flex flex-col justify-center items-center gap-10'>
                  <NoData
                      icon={<Search size={50}/>}
                  >
                      <h3 className='text-normal'>No Disputes <br /> Found</h3>
                  </NoData>
                </div>
              )
          }
        </>
      )
    }
  </div>
  )
}

export default ListResolvedDisputes